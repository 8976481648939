import CartButton from "./_cart_button";

export default class {
  constructor($card) {
    this.$card = $card;
    this.$addButton = $(document).find('.add-button');

    // Init Cart button
    new CartButton(this.$addButton);
  }
}
