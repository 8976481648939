export default class {
  constructor($button) {
    this.$button = $button;

    this.$button.on('ajax:success', () => {
      window.App.header.reloadCart();
    });
  }

  readParams() {
    return this.$button.attr('data-params');
  }

  writeParams(params) {
    this.$button.attr('data-params', params);
  }

  updateAmount(amount) {
    let params = this.readParams();

    // Remove amount
    params = params.substr(0, params.lastIndexOf("="))

    // Add amount
    params = `${params}=${amount}`

    this.writeParams(params);
  }
}
