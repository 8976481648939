export default class {
  constructor() {
    this.$table = $(document).find('.cart-table');
    this.$removeButtons = this.$table.find('.remove-button');

    this.$removeButtons.on('ajax:success', () => {
      location.reload();
    });
  }
}
