export default class {
  constructor($cart) {
    this.$cart = $cart;
    this.$counter = this.$cart.find('.counter')
  }

  updateItemsCount(count) {
    this.$counter.html(count);
  }
}
