import CartButton from './_cart_button'

export default class {
  constructor() {
    this.$addButton = $(document).find('.add-button');
    this.$amountInput =  $(document).find('select#amount');

    // Init Cart button
    let cartButton = new CartButton(this.$addButton);

    this.$amountInput.on('change', (e) => {
      let $input = $(e.currentTarget);
      let amount = parseInt($input.val());
      cartButton.updateAmount(amount);
    });
  }

}
