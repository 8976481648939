import ProductCard from "../products/_product_card";

export default class {
  constructor() {
    this.$productCards = $(document).find('.product-card');
    this.$productCards.each((index, element) => {
      new ProductCard($(element))
    })
  }
}
