export default class {
  constructor() {
    this.id = undefined;
    this.total_price = undefined;
    this.items_count = undefined;
  }

  fetch() {
    return $.ajax({
      url: API_ROOT_URL + "/cart",
      type: "GET",
      dataType: "json"
    });
  }
}
