/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/layouts/application.html.erb

// Uncomment to copy all static images under .../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('.../images', true)
// const imagePath = (name) => images(name, true)

import "core-js/stable";
import "regenerator-runtime/runtime";

const images = require.context('../images', true)
import '../webshop.sass'

// Vendor
import Rails from '@rails/ujs';
import Turbolinks from "turbolinks"
import SmoothScroll from '../vendor/smooth-scroll'
import viewportUnitsBuggyfill from 'viewport-units-buggyfill'
import LazyLoad from "vanilla-lazyload";
import Bootstrap from "bootstrap"
import "@fortawesome/fontawesome-free/js/all"
import '@fancyapps/fancybox/dist/jquery.fancybox'
import '@fancyapps/fancybox/dist/jquery.fancybox.css'

// Views
import Header from '../webshop/_header'
import Cart from '../webshop/shared/cart'
import Footer from '../webshop/_footer'
import MainIndex from '../webshop/main'
import CartsShow from '../webshop/carts/show'
import ProductsIndex from '../webshop/products'
import CategoriesShow from '../webshop/categories/show'
import ProductsShow from '../webshop/products/show'
import SitemapIndex from '../webshop/sitemap'
import PagesShow from '../webshop/pages/show'

// Init turbolinks
Rails.start();
Turbolinks.start();

// App
export default class App {
  constructor() {
    this.$body = $('body');
    this.controller = this.$body.attr('data-controller');
    this.action = this.$body.attr('data-action');

    // Init header
    this.cart = new Cart();
    this.header = new Header();
    this.footer = new Footer();
    this.initPage(this.controller, this.action);
  }

  initPage(controller, action) {
    (controller === 'main' && action === 'index') && new MainIndex();
    (controller === 'carts' && action === 'show') && new CartsShow();
    (controller === 'categories' && action === 'show') && new CategoriesShow();
    (controller === 'products' && action === 'index') && new ProductsIndex();
    (controller === 'products' && action === 'show') && new ProductsShow();
    (controller === 'sitemap' && action === 'index') && new SitemapIndex();
    (controller === 'pages' && action === 'show') && new PagesShow();
  }
}

// Initializers
document.addEventListener("turbolinks:load", function () {

  // Init viewport fix
  viewportUnitsBuggyfill.init();

  // Init smooth scrolling
  new SmoothScroll();

  // Lazy load images
  var lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy"
    // ... more custom settings?
  });

  // Set correct csrf token in cached partials
  $('input[name=authenticity_token]').val($('meta[name=csrf-token]').attr('content'));

  // Init App
  window.App = new App();
});
