import HeaderCart from '../webshop/shared/_header_cart'

export default class {
  constructor() {
    this.$header = $(document).find('.header');
    this.$headerCart = this.$header.find('.header-cart');
    this.headerCart = new HeaderCart(this.$headerCart);
  }

  reloadCart() {
    window.App.cart.fetch().done((data) => {
      this.headerCart.updateItemsCount(data.items_count);
    });
  }
}
